// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Api desarrollo
  // apiUrl: 'https://dev.wordiix-api.ii3.es/api',

  // api produccion 2023
  apiUrl: 'https://admin.wordiix.com/api',
   
   // API producción
   //apiUrl: 'https://wordiix-api.ii3.es/api',
  
  // API producción
  //apiUrl: 'http://127.0.0.1:8000/api',

  firebase: {
    apiKey: 'AIzaSyDnnMUdp0SbNYi4Ogks86YkQkYAg63wSnw',
    authDomain: 'chat-realtime-wordiixapp.firebaseapp.com',
    projectId: 'chat-realtime-wordiixapp',
    storageBucket: 'chat-realtime-wordiixapp.appspot.com',
    messagingSenderId: '172924335042',
    appId: '1:172924335042:web:ad903f348a55f013e59c5b',
    databaseURL:'chat-realtime-wordiixapp-default-rtdb.europe-west1.firebasedatabase.app'
  },
  webSocket:
    'ws://127.0.0.1:6001/app/AIzaSyDnnMUdp0SbNYi4Ogks86YkQkYAg63wSnw?protocol=7&client=js&version=4.3.1&flash=false',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
