import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, from, Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    return next
      .handle(req)
      .pipe(
        catchError((error) => {
          let errorMessage = '';
          if (error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            // backend error
            errorMessage = `Server-side error: ${error.status} ${error.message}`;
            //If error is Unauthenticated, clear cache and return lo Login Page

            if (error?.error?.message === 'Unauthenticated.') {
              localStorage.clear();
              this.router.navigateByUrl('/login');
            }
          }
          //Check if Loading is present
          // this.loadingController.dismiss();

          //Return custom Message
          // return throwError(errorMessage);
          console.log(error);
          return throwError(error.error);
        })
      )
      .toPromise();
  }
}
