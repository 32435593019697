import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { GeneralService } from 'src/app/services/general/general.service';

@Injectable({
  providedIn: 'root',
})

export class AuthTeacherGuard implements CanActivate {

  private user: any;
  private userInfo: any;

  constructor(
    private router: Router,
    private general: GeneralService
    ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let token = localStorage.getItem('access_token');

    await this.getUserInfo();
    
    if (typeof token === 'string' && this.userInfo.profile_completed == true && this.userInfo.register_completed == true && this.user.rol == 'teacher') {
      return true;
      
    } else {
      //si has iniciado sesión e intenta navegar por la app pero no has completado todo el registro, te mando a register-teacher
      if (
        typeof token === 'string' &&
        this.userInfo.profile_completed == true &&
        this.userInfo.register_completed == false &&
        this.user.rol === 'teacher'
      ) {
        this.router.navigate(['auth/register-teacher'], {
          queryParams: {
            return: state.url,
          },
        });
        return false;
      }

      this.router.navigate(['/auth/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return false;
    }
  }

  async getUserInfo() {
    const response = await this.general.getUserInfo();
    this.userInfo = response.data;
    this.user = this.userInfo.user;
  }
  
}
