import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  // private items: Observable<any[]>;

  constructor(private db: AngularFireDatabase) {}

  initSocket(chat_token: string) {
    try {
      let newItems = false;
      let channelRef = this.db.database.ref('channel/' + chat_token);
      channelRef.on('child_added', (message) => {
        if (!newItems) return;
        let res = message.val();
        window.dispatchEvent(
          new CustomEvent('app:new-message', {
            detail: {
              message: JSON.parse(res),
            },
          })
        );
      });

      channelRef.once('value', function (messages) {
        newItems = true;
      });
    } catch (error) {
      console.log(error);
    }
  }
}
