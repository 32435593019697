import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin as join, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '../../components/custom-snack-bar/custom-snack-bar.component';
import { User } from 'src/app/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private url: string;

  private loading = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable();

  private user: any;
  public user$: any;

  public userInfo: any;

  private noReadMessages: any;
  public noReadMessages$: any;

  private wallet: any;
  public wallet$: any;

  public chats: any = [];
  public notReadChatsCount: number = 0;


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public translate: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this.url = environment.apiUrl;

    this.initUser();
    this.initNoReadMessages();
    this.initWallet();
  }

  async initUser() {

    let token = localStorage.getItem('access_token');

    if (typeof token === 'string') {

      const response = await this.getUserInfo();
      this.userInfo = response.data;
      let usertemp = this.userInfo.user;
      this.user = new BehaviorSubject((usertemp));

    }else{
      this.user = new BehaviorSubject(null);
    }
    this.user$ = this.user.asObservable();

    /*
        let user = localStorage.getItem('user');
        
        if (user) {
          this.user = new BehaviorSubject(JSON.parse(user));
        } else {
          this.user = new BehaviorSubject(null);
        }
        this.user$ = this.user.asObservable();
    */
        
  }

  
  async loadChats() {
    const response = await this.httpGet('chat/load-chat-list');
    this.chats = response.data;
    this.notReadChatsCount = 0;
    this.chats.forEach((chat: any) => {
      this.notReadChatsCount += chat.count;
    });
    localStorage.setItem('noReadChats', JSON.stringify(this.notReadChatsCount));
  }

  initNoReadMessages() {
    this.noReadMessages = new BehaviorSubject(0);
    this.noReadMessages$ = this.noReadMessages.asObservable();
  }

  initWallet() {
    this.wallet = new BehaviorSubject('');
    this.wallet$ = this.wallet.asObservable();
  }

  /**
   * Set wallet
   * @param data
   */
  setWallet(data: any) {
    this.wallet.next(data);
  }

  /**
   * Set no read messages
   * @param count
   */
  setNoReadMessages(count: any) {
    this.noReadMessages.next(count);
  }

  /**
   * Set Loading
   * @param loading
   */
  setLoading(loading: any) {
    this.loading.next(loading);
  }

  async getUserInfo() {
    return this.httpGet('get-user-info');
  }

  /**
   * Make Http Get request
   * @param url
   * @param params
   * @returns
   */
  async httpGet(url: string, params = {}): Promise<any> {
    return this.http.get(`${this.url}/${url}`, { params: params }).toPromise();
  }

  /**
   * Make Http Post request
   * @param url
   * @param body
   * @param params
   * @returns
   */
  async httpPost(url: string, body: object, params = {}): Promise<any> {
    return this.http
      .post(`${this.url}/${url}`, body, { params: params })
      .toPromise();
  }

  /**
   * Make Http Put request
   * @param url
   * @param body
   * @param params
   * @returns
   */
  async httpPut(url: string, body: object, params = {}): Promise<any> {
    return this.http
      .put(`${this.url}/${url}`, body, { params: params })
      .toPromise();
  }

  /**
   * Make Http Delete request
   * @param url
   * @param params
   * @returns
   */
  async httpDelete(url: string, params = {}): Promise<any> {
    return this.http
      .delete(`${this.url}/${url}`, { params: params })
      .toPromise();
  }

  /**
   * Go back in history
   */
  back() {
    // this.router.navigate(['/home']);
    this.location.back();
  }

  /**
   * Go forward in history
   *
   * @param url
   */
  navigate(url: string, params: any = {}) {
    this.router.navigate([url], params);
  }

  /**
   * Get translations
   * @param key
   * @returns
   */
  async getTranslations(key: string | Array<string>) {
    return this.translate.get(key).toPromise();
  }

  /**
   * Return Fork Join
   */

  async forkJoin(requests: any) {
    return join(requests).toPromise();
  }

  /**
   * Check if is mobile
   *
   * @returns boolean
   */
  isMobile(): Boolean {
    return window.innerWidth <= 768;
  }

  /**
   * Show Alert
   * @param text
   */
  async showInfiniteWarning(text: any) {
    let translation = await this.getTranslations(text);
    this._snackBar.open(translation, 'X', {
      duration: undefined,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'warningSnackBar',
    });
  }

  /**
   * Show Alert
   * @param text
   */
  async showWarning(text: any) {
    let translation = await this.getTranslations(text);
    this._snackBar.open(translation, 'X', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'warningSnackBar',
    });
  }

  /**
   * Show Alert
   * @param text
   */
  async showDanger(text: any = 'app-error') {
    let translation = await this.getTranslations(text);
    this._snackBar.open(translation, 'X', {
      duration: 500000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'dangerSnackBar',
    });
  }

  /**
   * Show Alert
   * @param text
   */
  async showSuccess(text: any) {
    let translation = await this.getTranslations(text);
    this._snackBar.open(translation, 'X', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'successSnackBar',
    });
  }

  /**
   * Validate error from sending form and show message error
   * @param error
   * @returns
   */
  async showErrorForm(error: any): Promise<void> {
    let message: string;
    if (error.error) {
      message = '<div><ul>';
      Object.keys(error.error).forEach((key) => {
        error.error[key].forEach((line: any) => {
          message += `<li>${line}</li>`;
        });
      });
      message += '</ul></div>';
    } else if (error.data) {
      message = '<div><ul>';
      Object.keys(error.data).forEach((key) => {
        error.data[key].forEach((line: any) => {
          message += `<li>${line}</li>`;
        });
      });
      message += '</ul></div>';
    } else {
      message = error.message;
    }
    let translation = await this.getTranslations(message);
    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'dangerSnackBar',
      data: translation,
    });
  }

  /**
   * Return Act Url Query Params
   * @returns
   */
  getQueryParams() {
    return this.route.snapshot.firstChild?.queryParams;
  }

  /**
   * Return Act Url Params
   * @returns
   */
  getParams() {
    return this.route.snapshot.firstChild?.params;
  }

  /**
   * Update Query Params of the current Route
   * @param params
   */
  updateQueryParams(params: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /**
   * Generate random id
   *
   * @param length
   * @returns
   */
  public getRandomId(length: any) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Wait for n milliseconds
   */
  async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Return headers height
   * @param id
   * @returns
   */
  getHeaderHeight(id: string) {
    const header = document.getElementById(id);
    return header?.clientHeight;
  }

  /**
   * Open New Tab
   * @param url
   */
  openNewTab(url: string, params: any = {}) {
    params = new URLSearchParams(params).toString();
    const newTab = this.router.serializeUrl(this.router.createUrlTree([url]));

    window.open(`${newTab}?${params}`, '_blank');
  }
  /**
   * Get unique value from array
   * @param array
   * @param key
   * @returns
   */
  getArrayUniqueValues(array: any, keys: any): Array<any> {
    const arrayUniqueByKey = [
      ...new Map(
        array.map((item: any) => {
          const key = keys.map((key: any) => item[key]).join('-');
          return [key, item];
        })
      ).values(),
    ];

    return arrayUniqueByKey;
  }

  /**
   * Not available action
   */
  async displayNotAvailableAction() {
    this.showWarning('action-not-avaible-yet');
  }

    /**
   * Not available action
   */
    async onlyPremiumTeachers() {
      this.showWarning('only-premium-teachers');
    }

  /**
   * Get current Timezone
   */
  getCurrentTimezone() {
    let timezone = localStorage.getItem('timezone');
    if (!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      localStorage.setItem('timezone', timezone);
    }

    return timezone;
  }

  /**
   * Get current Locale
   */
  getCurrentLocale() {
    let locale = localStorage.getItem('locale');
    if (!locale) {
      let lang = localStorage.getItem('lang');

      if (!lang) {
        lang = 'es';
        localStorage.setItem('lang', lang);
      }
      locale = this.setLocale(lang);
    }
    return locale;
  }

  /**
   * Set current Locale
   */
  setLocale(lang: string) {
    let locale;
    switch (lang) {
      case 'en':
        locale = 'en-gb';
        break;
      case 'ru':
        locale = 'ru';
        break;
      default:
        locale = 'es-es';
    }
    localStorage.setItem('locale', locale);
    return locale;
  }

  async onSendMessage(teacher:any) {
    if (!this.user) {
      await this.navigate('auth/login');
      return;
    }

    const chat = {
      user_id: teacher.user_id,
      name: teacher.name,
      user_photo: teacher.photo_url,
      last_message: null,
    };

    localStorage.setItem('current-chat', JSON.stringify(chat));
    await this.sleep(10);
    await this.navigate('messages');
  }
}
