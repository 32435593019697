import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { GeneralService } from 'src/app/services/general/general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
  
    private router: Router,
    private general: GeneralService) {}

    private user: any;
    private userInfo: any;

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      let token = localStorage.getItem('access_token');
      
      try {

        await this.getUserInfo();

        let profile_completed = this.userInfo.profile_completed;

        if(this.user.rol == "student") {

          if (typeof token == 'string' && profile_completed == true) {
            return true;
          } else {
            this.router.navigate(['/auth/login'], {
              queryParams: {
                return: state.url,
              },
            });
            return false;
          }
    
        }else if(this.user.rol == "teacher") {
          
          let register_completed = this.userInfo.register_completed;
    
          if (typeof token == 'string' && profile_completed == true && register_completed == true) {
            return true;
          } else {
            this.router.navigate(['/auth/register-teacher'], {
              queryParams: {
                return: state.url,
              },
            });
            return false;
          }
    
        } else {
          return false;
        }

      } catch (error) {
        return false;
      }

    }

    async getUserInfo() {
      const response = await this.general.getUserInfo();
      this.userInfo = response.data;
      this.user = this.userInfo.user;
    }

}
