import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor as httpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

@Injectable()
export class HttpInterceptor implements httpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const lang = localStorage.getItem('lang');

    let token = localStorage.getItem('access_token');
    const toFormDataUrls = [
      'upd-teacher-profile-about-data',
      'upd-teacher-profile-certificates-data',
      'file-upload',
      'register-teacher-second',
      'register-student-second',
      'register-teacher-third',
      'register-teacher-fourth',
      'upd-student-profile-about-data',
      'update-photo',
      'chat/send-message',
    ];
    if (
      toFormDataUrls.some((value) => {
        return req.url.includes(value);
      })
    ) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-localization': lang ? lang : 'es',
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8;',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'X-localization': lang ? lang : 'es',
        },
      });
    }
    return next.handle(req).toPromise();
  }
}
