import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { GeneralService } from 'src/app/services/general/general.service';

@Injectable({
  providedIn: 'root',
})

export class AuthStudentGuard implements CanActivate {

  private user: any;
  private userInfo: any;

  constructor(
    private router: Router,
    private general: GeneralService
    ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    await this.getUserInfo();

    let token = localStorage.getItem('access_token');
    
    if (
      typeof token === 'string' &&
      this.userInfo.profile_completed == true &&
      this.user.rol == 'student'
    ) {
      return true;
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return false;
    }
  }

  async getUserInfo() {
    const response = await this.general.getUserInfo();
    this.userInfo = response.data;
    this.user = this.userInfo.user;
  }

}
